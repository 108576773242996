<template>
  <section>
    <services />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'GOryanGO'
      }
    },

    components: {
      Services: () => import('@/components/Services.vue')
    }
  }
</script>
